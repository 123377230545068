.label {
  cursor: pointer;
  margin-bottom: 0;
}

.button {
  margin-bottom: 10px;
}

.image {
  display: block;
  max-height: 300px;
  max-width: 300px;
  margin-bottom: 10px;
}

.removeIcon {
  margin-right: 10px;
}

.spinnerWrapper {
  margin-top: 10px;
}
