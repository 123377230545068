@import '../../../../styles/colors';

.col {
  text-align: center;
  margin-bottom: 20px;
}

.button {
  width: 100%;
}

.image {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 5px;
  border: 2px solid white;
}

.label {
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .image {
    width: 70px;
  }
}
