@import '../../../../styles/colors.scss';

.legendWrapper {
  width: 256px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 5px 5px;
  font-size: 1rem;
}
