@import 'typography';
@import 'colors';

.btn {
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

  &.btn-link {
    box-shadow: none;
  }
}

.btn-darken-primary {
  color: white !important;
  border: 1px solid white !important;
  background-color: $primary !important;

  &.outline {
    color: $primary !important;
    background: white !important;
    border: 1px solid $primary !important;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: white !important;
    background: $lighten-primary !important;
  }
}

.btn-rounded-warning {
  color: white !important;
  border: 1px solid white !important;
  background-color: $warning !important;
  border-radius: 25%;

  &.outline {
    color: $warning !important;
    background: white !important;
    border: 1px solid $warning !important;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: white !important;
    background: $darken-warning !important;
  }
}

.btn-warning {
  color: white !important;
  border: 1px solid $warning !important;
  background-color: $warning !important;

  &.outline {
    color: $warning !important;
    background: white !important;
    border: 1px solid $warning !important;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: white !important;
    background: $darken-warning !important;
  }
}

.btn-premium {
  color: white !important;
  border: 1px solid $premium !important;
  background-color: $premium !important;

  &.outline {
    color: $premium !important;
    background: white !important;
    border: 1px solid $premium !important;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: white !important;
    background: $darken-premium !important;
  }
}

.btn-light {
  color: $dark-grey !important;
  border: 1px solid $silver !important;
  background-color: white !important;

  &.outline {
    color: white !important;
    background: white !important;
    border: 1px solid white !important;
  }

  &:hover {
    opacity: 1;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: $dark-grey !important;
    background: white !important;
  }
}

.btn-rounded-light {
  color: $dark-grey !important;
  border: 1px solid $silver !important;
  background-color: white !important;
  border-radius: 50%;
  width: 46px;
  height: 46px;

  &.outline {
    color: white !important;
    background: white !important;
    border: 1px solid white !important;
  }

  &:hover {
    opacity: 1;
  }

  &:active,
  &:active:focus,
  &:active:hover,
  &:focus,
  &:hover {
    color: $dark-grey !important;
    background: white !important;
  }
}
