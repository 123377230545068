.navigationWrapper {
  margin-top: 20px;
}

.alert {
  font-size: 10px;
}

@media (max-width: 576px) {
  .label {
    font-size: 0.9em;
  }
}
