.buttonsWrapper {
  margin-top: 1rem;
  text-align: center;
  .buttonsText {
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.alert {
  margin-top: 1rem;
  font-size: 1rem;
}

@media (max-width: 576px) {
  .label {
    font-size: 0.9em;
  }
  .buttonsText {
    display: block;
    width: 100%;
    margin: 0.5rem 0 !important;
  }
  .button {
    display: block;
    width: 100%;
  }
  .alert {
    margin-top: 0.9rem;
    font-size: 0.9rem;
  }
}
