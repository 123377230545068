@import '../../../styles/colors';

.wrapper {
  position: absolute;
  left: 50%;
  top: 90px;
  margin-left: -496px;
  width: 992px;
  background: white;
  height: 88svh;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  border-radius: 5px;
  overflow-y: auto;
}

.header {
  background: white;
  margin-bottom: 0;
  color: $dark-grey;
  padding: 15px;
  font-weight: bold;
  text-align: center;
  &:first-letter {
    text-transform: uppercase;
  }
  .small {
    font-size: 0.6em;
    font-weight: normal;
  }
}

.closeButton {
  cursor: pointer;
  float: right;
}

.stepList {
  padding: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  list-style-type: none;
  justify-content: space-between;
}

.step {
  color: $silver;

  font-size: 1.1em;
  background: $lighten-grey;
  width: 25%;
  padding: 20px 10px;
  position: relative;
  text-align: center;
  &.isActive {
    background: $premium;
    color: white;
    font-weight: bold;
  }
}

.formWrapper {
  padding: 0 20px;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .wrapper {
    left: 0;
    margin-left: 0;
    width: 100%;
    top: 70px;
    height: 92svh;
  }
}

@media (max-width: 576px) {
  .step {
    font-size: 0.8em;
    padding: 5px 2px;
  }
  .contentWrapper {
    padding: 10px;
  }
}
