@import '../../../styles/colors';

$farming-forum-color: #0a678a;
$graindex-color: #364f69;

.wrapper {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 140px;
  right: 20px;
  z-index: 2000;
  opacity: 0.95;
  border-radius: 5px;
}

.header {
  color: white;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;

  &.Cereapro {
    background: #153e35;
  }

  &.Terre-Net {
    background: #f4872c;
  }

  &.Sencrop {
    background: #2a723f;
  }

  &.Farmr {
    background: #0f7001;
  }

  &.MaterielAgricole {
    background: #046ab4;
  }

  &.Graindex {
    background: $graindex-color;
  }

  &.TheFarmingForum {
    background: $farming-forum-color;
  }
}

.tableWrapper {
  padding: 0;
  margin-bottom: 0;

  a:not(.button) {
    color: $dark-grey !important;

    &:hover {
      text-decoration: none;
      color: $black !important;
    }
  }

  .button {
    &.Cereapro {
      background: #153e35;
      border: 1px solid #153e35;

      &:hover {
        background: darken(#153e35, 5);
        border: 1px solid darken(#153e35, 5);
      }
    }

    &.Terre-Net {
      background: #f4872c;
      border: 1px solid #f4872c;

      &:hover {
        background: darken(#f4872c, 5);
        border: 1px solid darken(#f4872c, 5);
      }
    }

    &.Sencrop {
      background: #2a723f;
      border: 1px solid #2a723f;

      &:hover {
        background: darken(#2a723f, 5);
        border: 1px solid darken(#2a723f, 5);
      }
    }

    &.Farmr {
      background: #0f7001;
      border: 1px solid #0f7001;

      &:hover {
        background: darken(#0f7001, 5);
        border: 1px solid darken(#0f7001, 5);
      }
    }

    &.MaterielAgricole {
      background: #046ab4;
      border: 1px solid #046ab4;

      &:hover {
        background: darken(#046ab4, 5);
        border: 1px solid darken(#046ab4, 5);
      }
    }

    &.Graindex {
      background: $graindex-color;
      border: 1px solid $graindex-color;

      &:hover {
        background: darken($graindex-color, 5);
        border: 1px solid darken($graindex-color, 5);
      }
    }

    &.TheFarmingForum {
      background: $farming-forum-color;
      border: 1px solid $farming-forum-color;

      &:hover {
        background: darken($farming-forum-color, 5);
        border: 1px solid darken($farming-forum-color, 5);
      }
    }
  }
}

.table {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0 !important;
  color: #333333 !important;
}

.closeButton {
  cursor: pointer;
  float: right;
}

.image {
  max-width: 100%;
  max-height: 256px;
  margin-bottom: 0.5rem;
}

@media (max-width: 576px) {
  .wrapper {
    left: 0;
    top: 70px;
  }
}
