@import '../../styles/colors';
@import '../../styles/typography';

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.95;
  width: 100%;
  background: $primary;
  height: 70px;
  align-items: center;
  padding: 10px;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

.partners,
.buttons {
  display: inline-block;
}

// BRAND

.logo {
  width: 250px;
  height: 43.75px;
  margin-right: 10px;
}

.logoResponsive {
  display: none;
}

// BUTTONS

.buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 50px;
}

@media (max-width: 992px) {
  .statisticButton {
    margin-left: auto !important;
  }
}

@media (max-width: 1280px) {
  .logo {
    display: none;
  }
  .logoResponsive {
    display: block;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

@media (max-width: 576px) {
  .logoResponsive {
    width: 55px;
    height: 55px;
  }
  .statisticLabel {
    display: none;
  }
}

@media (max-width: 375px) {
  .logoResponsive {
    width: 35px;
    height: 35px;
  }
}
