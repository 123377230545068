@import '../../../../styles/colors';

.wrapper {
  text-align: center;
  margin: 30px 0;
}

.previousButton {
  margin-right: 10px;
  margin-left: 5px;
}

.nextButton {
  margin-right: 10px;
  margin-left: 10px;
}

.skipButton {
  margin-right: 10px;
  margin-left: 10px;
}
