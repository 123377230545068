$info: #337ab7;

$warning: #f37f2b;
$darken-warning: #f27013;

$premium: #e9b537;
$darken-premium: #deab36;

$danger: #e4454d;

$lighten-primary: #619b48;
$primary: #5a9449;
$darken-primary: #508341;

$black: #2a2a2a;
$dark-grey: #565656;
$silver: #a1a5ac;
$faded-grey: #c0c0c0;
$medium-grey: #d6d6d6;
$light-grey: #e8e8e8;
$background-grey: #ececec;
$lighten-grey: #f8f8f8;

$rapeseed-color: #3d3d3d;
$wheat-color: #d0455c;
$corn-color: #cec63f;
$sunflower-color: #818181;
$barley-color: #d39750;
