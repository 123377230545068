.required:after {
  content: ' *';
  color: red;
}

@media (max-width: 576px) {
  .label {
    font-size: 0.9em;
  }
}
