@import '../../styles/colors';

.button {
  .icon {
    margin-right: 0.5rem;
  }
}

.modal {
  margin-bottom: 15px;
  .content {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #333;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}

.header {
  font-size: 1.2rem;
  font-weight: bold;
}

.partnerWrapper {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $background-grey;
}

.title {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}
.image {
  display: block;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  height: auto;
}

@media (max-width: 900px) {
  .label {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .label {
    display: none;
  }
  .button {
    .icon {
      margin-right: 0;
    }
  }
}
