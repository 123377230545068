.navigationWrapper {
  margin-top: 20px;
}

.periodInputGroup {
  margin-top: -1.5rem;

  span {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10px;
  }

  input {
    min-width: 145px !important;
    max-width: 160px !important;
  }
}

@media (max-width: 576px) {
  .label {
    font-size: 0.9em;
  }
}
