@import '../../../../styles/colors';

.toast {
  width: 300px;
  background: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.iconWrapper {
  margin-right: 7px;
  color: white;
  display: inline-block;
  padding: 2px 6px;
  &.success {
    background: $primary;
  }
  &.danger {
    background: $danger;
  }
}

.header {
  padding: 0.75em;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: $dark-grey;
}

.body {
  padding: 0.75em;
}

.closeButton {
  cursor: pointer;
  float: right;
}
