@import '../../../styles/colors';

.wrapper {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 140px;
  right: 20px;
  z-index: 2000;
  opacity: 0.95;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 80svh;
}

.header {
  background: $premium;
  color: white;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;

  &:first-letter {
    text-transform: uppercase;
  }

  .small {
    font-size: 0.6em;
    font-weight: normal;
  }

  &.BarleyObservation {
    background: $barley-color;
  }

  &.WheatObservation {
    background: $wheat-color;
  }

  &.SunflowerObservation {
    background: $sunflower-color;
  }

  &.RapeseedObservation {
    background: $rapeseed-color;
  }

  &.MaisGrainObservation {
    background: $corn-color;
  }
}

.tableWrapper {
  padding: 0;

  margin-bottom: 0;
}

.table {
  font-size: 12px;
  margin-bottom: 0 !important;
  color: #333333 !important;
}

.closeButton {
  cursor: pointer;
  float: right;
}

.image {
  max-width: 100%;
  max-height: 350px;
}

@media (max-width: 576px) {
  .wrapper {
    left: 0;
    top: 70px;
  }
}
