@import '../../../styles/colors';

.wrapper {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 140px;
  right: 20px;
  z-index: 2000;
  opacity: 0.95;
  border-radius: 5px;
}

.header {
  background: #2aa7bf;
  color: white;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
  &:first-letter {
    text-transform: uppercase;
  }
}

.tableWrapper {
  padding: 0;
  margin-bottom: 0;
}

.table {
  font-size: 16px;
  margin-bottom: 0 !important;
  color: #333333 !important;
  p {
    margin-bottom: 0;
  }
  strong {
    font-size: 18px;
  }
}

.closeButton {
  cursor: pointer;
  float: right;
}

.image {
  max-width: 100%;
  max-height: 350px;
}

.button {
  background: #2aa7bf !important;
  border: 1px solid #2aa7bf !important;
  &:hover {
    background: darken(#2aa7bf, 5) !important;
    border: 1px solid darken(#2aa7bf, 5) !important;
  }
}

@media (max-width: 576px) {
  .wrapper {
    left: 0;
    top: 70px;
  }
}
